import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/wolfymaster.com/wolfymaster.com/src/templates/subpageTemplate.js";
import { Col, Row, Container, Button } from "react-bootstrap";
import Gallery from 'react-grid-gallery';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="project-header" style={{
      color: "white",
      display: "flex",
      height: "600px",
      alignItems: "flex-start",
      flex: "0 0 100%",
      flexDirection: "column",
      justifyContent: "center",
      background: "url('https://cdn.pixabay.com/photo/2012/03/01/00/36/abstract-19731_960_720.jpg')",
      backgroundSize: "100%"
    }}>
    <div className="title" style={{
        fontSize: "38px",
        paddingLeft: "20%"
      }}>Mentorship Framework</div>
    <div className="description" style={{
        fontSize: "18px",
        paddingLeft: "20%"
      }}>An easy way to bring professional development to your organization</div>
    <div className="links" style={{
        margin: "60px auto -120px",
        textAlign: "center",
        width: "100%"
      }}>
        <Button variant="outline-light" size="lg" href="https://www.github.com" target="_blank" mdxType="Button">View on Github</Button>&nbsp; &nbsp;
        <Button variant="outline-light" size="lg" href="https://www.mentorerie.com" target="_blank" mdxType="Button">View Website</Button>
    </div>
    </div>
    <Container style={{
      padding: "50px 0"
    }} mdxType="Container">
    <Row mdxType="Row">
        <Col lg={{
          span: 10,
          offset: 1
        }} mdxType="Col">
            <div style={{
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "bold",
            padding: "20px 0"
          }}>What A Professional Network Should Be</div>
            <div style={{
            textAlign: "center",
            fontSize: "18px"
          }}>
            We believe that payments is a problem rooted in code, not finance. We obsessively seek out elegant, composable abstractions that enable robust, scalable, flexible integrations. Because we eliminate needless complexity and extraneous details, you can get up and running with Stripe in just a couple of minutes.
            </div>
        </Col>
    </Row>
    </Container>
    <Container className="bullets" mdxType="Container">
    <Row mdxType="Row">
        <Col lg={{
          span: 3
        }} mdxType="Col">
            <div className="bullet">
                <div className="heading">Build a Network</div>
                <div className="description">organically grow your professional network by connecting with mentors and sharing your expertise across the organization<br />personal information is hidden by default and only revealed to connections you choose.</div>
            </div>
        </Col>
        <Col lg={{
          span: 3
        }} mdxType="Col">
            <div className="bullet">
                <div className="heading">Find a Mentor</div>
                <div className="description">connect with someone who can provide advice and insight. You may be looking at growing your career or developing a skill like public speaking.</div>
            </div>
        </Col>
        <Col lg={{
          span: 3
        }} mdxType="Col">
            <div className="bullet">
                <div className="heading">Mentor Others</div>
                <div className="description">share your knowledge and expertise with your organization by mentoring others.</div>
            </div>
        </Col>
        <Col lg={{
          span: 3
        }} mdxType="Col">
            <div className="bullet">
                <div className="heading">Expand Your Org</div>
                <div className="description">any professional organization can benefit through a mentoring network. Easily allow your members to grow and connect with one another.</div>
            </div>
        </Col>
    </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      